<template>
	<div class="filter-field filter-field-period">
		<BaseDropdown
			:clickaway="clickaway"
			text="Zeitabschnitt"
			title="Nach Zeitabschnitt filtern"
		>
			<FilterItem
				v-for="(item, key) in periods"
				:key="key"
				:current="period"
				:item="item"
				@change="_onChange"
			/>
		</BaseDropdown>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';
import {groupBy} from '@schascha/brabbelback';

export default {
	components: {
		FilterItem: () => import(/* webpackChunkName: "FilterItem" */ '@/components/filter/FilterItem')
	},
	mixins: [FilterMixin],
	props: {
		clickaway: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState(['period']),
		periods() {
			return groupBy(this.$store.state.periods, 'erathem');
		}
	},
	methods: {
		_onChange(e) {
			this.changeFilter('period', e);
		}
	}
};
</script>
